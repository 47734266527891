import React from 'react'
import Layout from '../components/Layout'

const About = () => {
    return (
        <Layout pageTitle="About">
          <p>I'm a flaneur on the internet. Currently, I'm self employed in crypto. I graduated from Mumbai University in 2020. I have previously worked in web development and data science. I like memes, electronic music, and philosophy. I spend a lot of time on the internet, reading, writing and talking to people. I try to make my personal notes more legible at <a href='https://twitter.com/ashishgpt2'>@ashishgpt2</a> on Twitter (DMs open!)</p>
          <p>This website was built using gatsby and fleek. It is hosted on IPFS and resolved using ENS.</p>
        </Layout>
      )
  }

export default About
